import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import OurHistory from '../components/AboutUs/OurHistory'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
import HowItWork from '../components/AboutUs/HowItWork'
import TeamMembers from '../components/AboutUs/TeamMembers'
import Testimonials from '../components/AboutUs/Testimonials'
import Partner from '../components/AboutUs/Partner'
 
const AboutUs = () => {
    return (
        <Layout
            titulo="Acerca de nosotros"
            descripcion="Acerca de do{main} México: nuestra historia, valores, misión y testimonios de nuestros clientes"
            slug="about-us"
            imagen="https://res.cloudinary.com/dev-empty/image/upload/v1630907493/project_start1_fcd74cc421.png"
        >
            <Navbar />

            <PageBanner
                pageTitle="Sobre Nosotros" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Quiénes somos" 
            />

            <AboutUsContent />

            <OurHistory />

            <WhyChooseUs />

            <HowItWork />

            <TeamMembers />

            <Testimonials />

            <Partner />
            
            <Footer />
        </Layout>
    );
}

export default AboutUs;